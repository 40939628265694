import { CardImgTitleMidContainer, CITMFeatures, CITMImg, CITMImgContainer, CITMTitle} from "./card-img-title-mid.styles";
import List from "../../list/list.component";

export default function CardImgTitleMid({ name, features, img}) {
    return (
        <CardImgTitleMidContainer>
            <CITMImgContainer>
                <CITMImg src={img} alt={name} />
            </CITMImgContainer>
            <CITMTitle>{name}</CITMTitle>
            <CITMFeatures>
                <List array={features} />
            </CITMFeatures>
        </CardImgTitleMidContainer>
    );
}