import {CLDescriptions, CLLogoContainer, CLTitle, CardLogoContainer } from "./card-logo.styles";

export default function CardLogo({logo, title, description}) {
    return (
        <CardLogoContainer>
            <CLLogoContainer>
                {logo}
            </CLLogoContainer>
            <CLTitle>
                {title}
            </CLTitle>
            <CLDescriptions>
                {description}
            </CLDescriptions>
        </CardLogoContainer>
    )
}