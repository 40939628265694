export function inputFormatter(e, format) {
    const skipList = makeSkiplist(format);
    const inputChar = e.nativeEvent.data;
    const inputText = e.target.value;
    const cursorAt = Number(e.target.selectionStart);

    const isDigit = /^\d+$/.test(inputChar);
    const isBackspace = e.nativeEvent.inputType === "deleteContentBackward";
    const isDelete = e.nativeEvent.inputType === "deleteContentForward";
    const isRemovalEvent = isDelete || isBackspace;

    if ((!isRemovalEvent && !isDigit)) {
        return;
    }

    let skipListLength = Object.keys(skipList).length;
    
    let cleaned = inputText.replace(/\D/g, "");
    cleaned = cleaned.slice(0, format.length - skipListLength);

    let finalValue = "";
    if (cleaned && cleaned.length > 0) {
        let numOfSkipChars = 0;
        for (let i = 0; i < format.length; i++) {
            if (i - numOfSkipChars >= cleaned.length) {
                break;
            }
            
            if (skipList[i + 1]) {
                finalValue += skipList[i + 1];
                numOfSkipChars++;
            } else {
    
                finalValue += cleaned[i - numOfSkipChars];
            }
        }
    }

    let newCurrCursor = cursorAt;
    if (isRemovalEvent) {
        if (isBackspace) {
            while (skipList[newCurrCursor] && newCurrCursor > 0) {
                newCurrCursor--;
            }
        } else {
            while(skipList[newCurrCursor + 1]) {
                newCurrCursor++;
            }
        }
    }else {
        while (skipList[newCurrCursor]) {
            newCurrCursor++;
        }
    }

    return {formattedValue: finalValue, formattedCursorPosition: newCurrCursor};
}

function makeSkiplist(format) {
    const skipList = {};
    for (let i = 0; i < format.length; i++) {
        if (format[i] !== 'x') {
            skipList[i + 1] = format[i];
        }
    }

    return skipList;
}