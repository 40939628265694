import styled from "styled-components";
import { BOX_SHADOW_OFFSET, BOX_SHADOW_SIZE, box_shadow } from "../../styles/util.styles";
import { Link } from 'react-router-dom';

export const PrimaryButtonStyle = styled.button`
    font-family: inherit;
    padding: 2rem 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: none;
    cursor: ${props => props.disabled ? 'wait' : 'pointer'};
    transition: all 0.3s;

    color: ${props => props.theme.components.button_primary_text};
    background-color: ${props => props.theme.components.button_primary_bg};

    &:hover:not(:disabled) {
        transform: translateY(-1px);
        ${box_shadow(BOX_SHADOW_SIZE.sm, BOX_SHADOW_OFFSET.both)}

        color: ${props => props.theme.components.button_primary_text_hover};
        background-color: ${props => props.theme.components.button_primary_bg_hover};
    }
`

export const SecondaryButtonStyle = styled(PrimaryButtonStyle)`
    padding: 1.5rem 1rem;
    text-transform: None;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 2rem;
    transition: all 0.3s;
    
    color: ${props => props.theme.components.button_secondary_text};
    background-color: ${props => props.theme.components.button_secondary_bg};
    border-bottom: 1px solid ${props => props.theme.components.button_secondary_bg_text};
    
    &:hover:not(:disabled) {
        color: ${props => props.theme.components.button_secondary_bg_text_hover};
        background-color: ${props => props.theme.components.button_secondary_bg_hover};
    }
`

export const LinkedStyle = styled(Link)`
    color: currentColor;
`

export const InvertedPrimaryButtonStyle = styled(PrimaryButtonStyle)`
    color: ${props => props.theme.components.button_primary_bg};
    background-color: ${props => props.theme.components.button_primary_text};

    &:hover:not(:disabled) {
        color: ${props => props.theme.components.button_primary_bg_hover};
        background-color: ${props => props.theme.components.button_primary_text_hover};
    }
`