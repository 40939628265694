import styled from "styled-components";

export const BlogImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    background-color: ${props => props.theme.colors.secondary_2};
`
    
export const BIStyle = styled.img``

export const BICaption = styled.figcaption`
    font-size: 1.2rem;
    font-style: italic;
`

export const BIContainer = styled.figure`
    width: 100%;
    padding: 1rem;
`