import './App.styles.jsx';
import { Routes, Route } from 'react-router-dom';
import Navigation from './routes/navigation/navigation.component';
import Home from './routes/home/home.component';
import Portfolio from './routes/portfolio/portfolio.component';
import ContactUs from './routes/contact-us/contact-us.component';
import Services from './routes/services/services.component';
import NotFound from './routes/not-found/not-found.component';
import { PAGE_NAMES } from './styles/util.styles.jsx';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path={PAGE_NAMES.PORTFOLIO} element={<Portfolio />} />
        <Route path={PAGE_NAMES.SERVICES} element={<Services />} />
        <Route path={PAGE_NAMES.CONTACT} element={<ContactUs />} />
        <Route path="*" element={<NotFound />} /> {/* Route for 404 page */}
      </Route>
    </Routes>
  );
}
