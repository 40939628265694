import { SERVICES } from '../../assets/data/services'
import {ServicesContainer, STitle, SSubTitle, SSection, SFImportance, SFImportances, SFImportanceListContainer, SFImportanceTitle, SCTO, SCTOTitle, SCTOSubTitle, SCTOBtn, SSubTitleContainer } from './services.styles'
import BlogSection from '../../components/blog-section/blog-section.component';
import List, { LINE_START_STYLE } from '../../components/list/list.component';
import { PAGE_NAMES } from '../../styles/util.styles';
import {PrimaryButton} from '../../components/buttons/button.component'

export default function Services() {

    function formatImportances(importances) {
        return importances.map(importance => <SFImportance><b>{importance.title}</b>: {importance.description}</SFImportance>)
    }

    return (
        <ServicesContainer>
            <STitle>Services</STitle>
            <SSubTitleContainer>
                <SSubTitle>We design and optimize your website for search engines, while also managing your advertising campaigns.</SSubTitle>
            </SSubTitleContainer>

            {SERVICES.map((service, index) => {
                return (
                    <SSection key={index}>
                        <BlogSection title={service.title} paragraphs={service.detailed.description} />
                        <SFImportances>
                            <SFImportanceTitle>To recap, these are the importance of {service.title}: </SFImportanceTitle>
                            <SFImportanceListContainer>
                                <List array={formatImportances(service.detailed.importance)} startStyle={LINE_START_STYLE.CHEVRON} />
                            </SFImportanceListContainer>
                        </SFImportances>
                    </SSection>
                )
            })}

            <SCTO>
                <SCTOTitle>Ready to get started?</SCTOTitle>
                <SCTOSubTitle>Get in touch with us today to start your SEO journey!</SCTOSubTitle>
                <SCTOBtn>
                    <PrimaryButton to={PAGE_NAMES.CONTACT}>Contact Us</PrimaryButton>
                </SCTOBtn>
            </SCTO>
        </ServicesContainer>
    )
}