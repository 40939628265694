import styled from "styled-components";

export const ListContainer = styled.ul`
    list-style: none;
    padding: 0;
`
    
export const LItem = styled.li`
    display: flex;
    padding-top: 1rem;
    width: 100%;
`

export const LStart = styled.div`
    margin-right: 1rem;
`

export const LText = styled.p`
    font-size: inherit;
`
export const LContent = styled.div`
    width: 100%;
`