import { NavbarContainer, NBLogoContainer, NBLinksContainer, NBLink, NBBurgerContainer, NBBurger, NBLogoFigure } from "./navbar.styles"
import { useState } from "react"

export default function Navbar({ links, logo }) {
    const [isBurgerClicked, setIsBurgerClicked] = useState(false)

    const handleBurgerClick = () => {
        setIsBurgerClicked(!isBurgerClicked)
    }

    return (
        <NavbarContainer className={isBurgerClicked ? 'sticky' : ''}>
            <NBLogoContainer to="/">
                <NBLogoFigure>
                    {logo}
                </NBLogoFigure>
            </NBLogoContainer>
            <NBBurgerContainer onClick={handleBurgerClick} className={isBurgerClicked ? 'clicked' : ''}>
                <NBBurger />
            </NBBurgerContainer>
            <NBLinksContainer onClick={handleBurgerClick} className={isBurgerClicked ? 'phoneAppear' : ''}>
                {
                links.map((link, index) => {
                    return (
                        <NBLink key={index} to={link.address}>{link.text ? link.text : <figure>{link.logo}</figure>}</NBLink>
                        )
                    })
                }
            </NBLinksContainer>
        </NavbarContainer>
    )
}