import { FORM_GROUP_TYPES } from '../formgroup-flyinglabel/formgroup-flyinglabel.component';
import { FormInputStyle, FormTextareaStyle } from './form-input.styles';
import { useState, useEffect, useRef } from 'react';
import { inputFormatter } from './input-formatter';

export default function FormInput({ onChangeCallback, isSubmitted, type, format, ...otherProps }) {
    const [value, setValue] = useState('');
    const [cursorPosition, setCursorPosition] = useState(0);

    const fieldRef = useRef();

    useEffect(() => {
        if (format) {
            fieldRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [cursorPosition, value, format]);

    const changeHandler = (e) => {
        if (format) {
            const formattedInfo = inputFormatter(e, format);
            if (formattedInfo) {
                setValue(formattedInfo.formattedValue);
                setCursorPosition(formattedInfo.formattedCursorPosition);
            }
        } else {
            setValue(e.target.value);
        }

        if (onChangeCallback) {
            onChangeCallback(e);
        }
    }

    useEffect(() => {
        isSubmitted && setValue('');
    }, [isSubmitted]);

    const UserInput = type === FORM_GROUP_TYPES.TEXTAREA ? FormTextareaStyle : FormInputStyle
    
    return (
        <UserInput ref={fieldRef} value={value} onChange={changeHandler} {...otherProps} /> 
    )
}