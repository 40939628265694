import { Ads, SEO, WebDesign } from '../icons/index';
import SEOImportanceSVG from '../seo-importance.svg';
import WebDesignImportanceSVG from '../web-design-importance.svg';
import AdsSEImportanceSVG from '../ads-importance-se.svg';
import AdsSOCImportanceSVG from '../ads-importance-soc.svg';

export const SERVICES = [
    {
        title: 'SEO',
        icon: <SEO />,
        detailed: {
            description: [
                {
                    text: 'SEO is the practice of optimizing your website to make it more visible on search engines. It involves strategies like keyword research, content creation, and link building. The goal is to attract organic (non-paid) traffic from the search engine results page.',
                },
                {
                    text: 'The chart below shows the importance of SEO in digital marketing. As shown below, organic search is as important as other digital marketing channels combined.',
                    img: {
                        src: SEOImportanceSVG,
                        alt: 'SEO Importance',
                        figcaption: '(Fig 1.1) Importance of SEO in Digital Marketing. Organic search is as important as other digital marketing channels combined.',
                    },
                },
            ],

            importance: [
                {
                    title: 'Visibility and Rankings',
                    description: 'Good SEO practices improve the visibility of your website on search engines, meaning your site is more likely to stand out against competitors and attract more visitors.',
                },
                {
                    title: 'User Experience',
                    description: 'SEO involves making your site user-friendly. A well-structured, uncluttered website compels a casual visitor to stay longer, thereby decreasing bounce rate and increasing page views.',
                },
                {
                    title: 'Growth',
                    description: 'An optimized website is more likely to have increased engagement, more conversions (e.g., sales, leads), which translates to business growth.',
                },
            ],
        },
        summary: {
            description: 'We provide the best SEO services to help you get more organic traffic and leads.',
        },
    },
    {
        title: 'Web Design',
        icon: <WebDesign />,
        detailed: {

            description: [
                { text: 'Web design involves creating an attractive, user-friendly website. It includes aspects like layout, color scheme, graphics, and content. It\'s not just about making a site look good - it\'s about designing a site that\'s easy to navigate and fulfills the user\'s needs.' },
                {
                    text: 'Even if a website is fully search engine optimized, it won\'t be successful if it doesn\'t look good and is hard to navigate. Below are some fascinating facts about user experience and web design.',
                    img:
                    {
                        src: WebDesignImportanceSVG,
                        alt: 'Web Design Importance',
                        figcaption: '(Fig 2.1) A poorly designed website can result in lost business. 88% of users won\'t return after a bad experience, and 38% will leave the website without engaging with it.',
                    },
                },
            ],

            importance: [
                {
                    title: 'First Impressions',
                    description: 'Your website is often a customer\'s first impression of your business. A well-designed website can help to establish trust and guide visitors to take action.',
                },
                {
                    title: 'Navigation and Accessibility',
                    description: 'Good web design ensures that your site is easy to navigate, meaning visitors can find what they\'re looking for quickly and easily.',
                },
                {
                    title: 'Consistency',
                    description: 'Consistent design across all pages makes your site look professional and effectively reinforces your brand.',
                },
            ],
        },
        summary: {
            description: 'We create beautiful and responsive websites that help you stand out from the competition.',
        },
    },
    {
        title: 'Ads',
        icon: <Ads />,
        detailed: {
            description: [
                { text: 'Advertising involves promoting your products or services to reach potential customers. It can be done through various channels like social media, search engines, email, and more. The goal is to help your business reach a larger audience and increase brand awareness.' },
                {
                    text: 'With more than 91% of search engine market share, Google Ads would be one of the most effective ways to grow your business. We are a member of the Google Ads partnership program and will help launch your ad campaigns.',
                    img: {
                        src: AdsSEImportanceSVG,
                        alt: 'Search Engine Advertising ROI',
                        figcaption: '(Fig 3.1) Search Engine Advertising ROI. Google Ads is widely recognized as the most effective platform for driving business growth.',
                    },
                },
                {
                    text: 'Social media advertising is another effective way to reach your target audience. Below are the top 6 social media companies with the highest advertisement ROI.',
                    img: {
                        src: AdsSOCImportanceSVG,
                        alt: 'Social Media Advertising ROI',
                        figcaption: '(Fig 3.2) Social media ad ROI. Facebook and Instagram are the top 2, followed by YouTube and TikTok.',
                    },
                },
            ],

            importance: [
                {
                    title: 'Reach',
                    description: 'Advertising allows you to reach a large number of potential customers, many of whom might not otherwise know about your business.',
                },
                {
                    title: 'Brand Awareness',
                    description: 'Effective advertising increases awareness of your brand among consumers, making them more likely to think of your business when they need the products or services you offer.',
                },
                {
                    title: 'Increased Sales',
                    description: 'By reaching more people and making them aware of your brand, advertising ultimately leads to increased sales.',
                },
            ],
        },
        summary: {
            description: 'We can help you create and manage advertising campaigns to reach your target audience.',
        },
    },
];
