import { Outlet } from 'react-router-dom';
import Navbar from "../../components/navbars/navbar.component";
import Footer from "../../components/footers/footer.component";
import SvgLogo from "../../assets/icons/logo.js"
import SvgHome from "../../assets/icons/home.js"

export default function Navigation() {    

    const links=[
        {address: "/services", text: "Services"},
        {address: "/portfolio", text: "Portfolio"},
        {address: "/contact", text: "Contact Us"},
        {address: "/", logo:<SvgHome/>},
    ]

    return (
        <>
            <Navbar
                links={links}
                logo={<SvgLogo />}
            />

            <Outlet />

            <Footer 
                links={links}
                legal="© 2024 SEOVATORS. ALL RIGHTS RESERVED."
            />
        </>
    )
}