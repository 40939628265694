import * as React from "react";

const SvgLogo = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 130 60"
  width="100%"
  {...props}
  >
  <text
    x="50%"
    y="50%"
    fill="currentColor"
    dominantBaseline="middle"
    fontFamily="Poppins"
    fontSize={20}
    fontWeight={400}
    textAnchor="middle"
    letterSpacing=".1rem">
    {"SEOvators"}
  </text>
  </svg>
);
export default SvgLogo;
