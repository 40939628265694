import { InvertedPrimaryButtonStyle, SecondaryButtonStyle, PrimaryButtonStyle, LinkedStyle } from "./button.styles"
import Spinner from "./spinner/spinner.component";

export function PrimaryButton(props) {
    return createButton(props, BUTTON_TYPES.PRIMARY);
}

export function InvertedPrimaryButton(props) {
    return createButton(props, BUTTON_TYPES.INVERTED_PRIMARY);
}

export function SecondaryButton(props) {
    return createButton(props, BUTTON_TYPES.SECONDARY);
}

const BUTTON_TYPES = {
    PRIMARY: 'primary',
    INVERTED_PRIMARY: 'inverted_primary',
    SECONDARY: 'secondary',
}


function createButton(props, type) {
    
    let DisplayButton = PrimaryButtonStyle;
    
    switch (type) {
        case BUTTON_TYPES.INVERTED_PRIMARY:
            DisplayButton = InvertedPrimaryButtonStyle;
            break;
        case BUTTON_TYPES.SECONDARY:
            DisplayButton = SecondaryButtonStyle;
            break;
        default:
            break;
    }

    return ( 
        <Button
            content={
                <DisplayButton {...props}>
                    { props.loading ? <Spinner />: props.children }
                </DisplayButton>
            }
            {...props} />
    )
}

function Button(props) {
    if (props.to) {
        return(
            <LinkedStyle to={props.to}>
                {props.content}
            </LinkedStyle>
        )
    } else {
        return (
            <>
                {props.content}
            </>
        )
    }
}