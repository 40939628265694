import styled from "styled-components"

export const ServicesContainer = styled.div`
    padding: 4rem;
`

export const STitle = styled.h1`
    text-align: center;
`

export const SSubTitle = styled.h4`
    text-align: center;
`

export const SSubTitleContainer = styled.div`
    position: relative;
    padding: 3rem;
    margin-bottom: 5rem;
    /* box-shadow:1rem 1rem 1rem 1rem ${props => props.theme.colors.secondary}; */
    text-transform: uppercase;
    border-bottom: 0.1rem solid ${props => props.theme.colors.secondary};

`

export const SSection = styled.div`
    padding-bottom: 10rem;
`

export const SFImportance = styled.p`
    font-size: 1.6rem;
`

export const SFImportances = styled.div``

export const SFImportanceTitle = styled.h3``

export const SFImportanceListContainer = styled.div`
    padding-left: 3rem;
    padding-bottom: 3rem;
    border-bottom: 0.2rem solid ${props => props.theme.colors.secondary};
`

export const SCTO = styled.div`
    text-align: center;
`

export const SCTOTitle = styled.h2`
    color: ${props => props.theme.colors.tertiary};
    text-align: center;
    padding-bottom: 2rem;
`

export const SCTOSubTitle = styled.p`
    padding-bottom: 2rem;
`

export const SCTOBtn = styled.div``