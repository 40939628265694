import BlogImg from '../../components/imgs/blog-img/blog-img.component';
import {BlogSectionContainer, BSTitle, BSParagraphSection, BSParagraphText, BSParagraphImg} from './blog-section.styles'

export default function BlogSection({title, paragraphs}) {

    return (
        <BlogSectionContainer>
            <BSTitle>{title}</BSTitle>
            {
                paragraphs.map((paragraph, index) => (
                    <BSParagraphSection key={index}>
                        <BSParagraphText>{paragraph.text}</BSParagraphText>
                        <BSParagraphImg>
                            <BlogImg img={paragraph.img} />
                        </BSParagraphImg>
                    </BSParagraphSection>
                ))
            }
        </BlogSectionContainer>
    )
}