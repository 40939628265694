import { ListContainer, LItem, LStart, LText, LContent } from "./list.styles"
import SvgChevron from "../../assets/icons/Chevron.tsx"
import React from "react"

export default function List({array, startStyle}) {
    let ItemStartIcon = null
    switch (startStyle) {
        case LINE_START_STYLE.CHEVRON:
            ItemStartIcon = <SvgChevron />
            break;
        default:
            break;
    }
    
    const lis = array.map((item, index) => (
        <LItem key={index}>
            {ItemStartIcon && 
                <LStart>
                    {ItemStartIcon}
                </LStart>
            }
            <LContent>
                {React.isValidElement(item) ?
                    item :
                    <LText>
                        {item}
                    </LText>
                }
            </LContent>
        </LItem>
    ))

    return (
        <ListContainer>
            {lis}
        </ListContainer>
    )
}

export const LINE_START_STYLE = {
    CHEVRON: "CHEVRON"
}