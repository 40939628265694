import styled from "styled-components"  
import { NAVBAR_HEIGHT } from "../navbars/navbar.styles"
import { responsive } from "../../styles/util.styles"

export const FooterContainer = styled.div`
    display: grid;
    height: ${NAVBAR_HEIGHT};
    align-items: center;
    margin: 0 2rem;
    grid-template-columns: max-content 1fr;

    ${responsive.mobile.tablet`
        gap: 2rem;
        padding: 2rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, ${NAVBAR_HEIGHT/2});
        height: 100%;
    `}
`

export const FLegal = styled.p`
    font-size: 1.4rem;

    ${responsive.mobile.tablet`
        text-align: center;
        font-size: 1rem;
    `}
`

export const FLinks = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-left: auto;
    gap: 2rem;
    text-decoration: none;
    flex-wrap: wrap;

    ${responsive.mobile.tablet`
        grid-row: 1/-1;
        grid-column: 1/2;
        justify-content: space-around;
        margin-left: 0;
    `}
`

export const FLink = styled.a`
    text-decoration: none;
    color: inherit;

    &::after {
        content: "";
        background-color: currentColor;
        height: 0.1rem;
        width: 100%;
        display: inline-block;
        transform: scale(0);
        transition: all .2s;

        ${responsive.mobile.tablet`
            display: none;
        `}
    }
    &::before {
        content: "";
        width: 100%;
        display: inline-block;

        
        ${responsive.mobile.tablet`
            display: none;
        `}
    }

    &:hover::after{
        transform: scale(1);
    }
`