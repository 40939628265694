import { CUSTOMERS } from "../../assets/data/customers"
import { SecondaryButton } from "../../components/buttons/button.component"
import CardImgTitleMid from "../../components/cards/card-img-title-mid/card-img-title-mid.component"
import { PortfolioContainer, PWebsiteBtnContainer } from "./portfolio.styles"

export default function Portfolio() {
    return (
        <PortfolioContainer>
            {CUSTOMERS.map(({customerName, description, pageUrl, pageImg}, index) => (
                <CardImgTitleMid
                    key={index}
                    name={customerName}
                    features={[
                        description,
                        <PWebsiteBtnContainer>
                            <SecondaryButton to={pageUrl}>Visit {customerName}</SecondaryButton>
                        </PWebsiteBtnContainer>
                    ]}
                    img={pageImg}/>
            ))}
        </PortfolioContainer>
    )
}