import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {ColorTheme, ComponentsTheme, ImagesTheme} from './styles/color-theme';
import {GlobalStyleComponent} from './styles/global.styles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={{
          colors: ColorTheme,
          components: ComponentsTheme,
          images: ImagesTheme}}>
        <GlobalStyleComponent />

        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
