import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {LinkStyleReset, responsive} from '../../styles/util.styles';

export const NAVBAR_HEIGHT = '7rem';

export const NavbarContainer = styled.div`
    color: ${props => props.theme.components.navbar_text};
    background-color: ${props => props.theme.components.navbar_bg};
    width: 100%;
    height: ${NAVBAR_HEIGHT};
    display: grid;
    grid-template-columns: [logo-start] max-content [logo-end links-start] 1fr [links-end];
    grid-template-rows: 100%;

    ${responsive.mobile.phone`
        &.sticky{
            position: fixed;
            top: 0;
            z-index: 999;
        }
    `}
`

export const NBLogoContainer = styled(Link)`
    ${LinkStyleReset}
    width: 10rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
`

export const NBLink = styled(Link)`
    ${LinkStyleReset}
    padding: 0 2rem;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.2s;

    &:hover {
        color: ${props => props.theme.components.navbar_bg};
        background-color: ${props => props.theme.components.navbar_text};
        transform: translateY(-0.2rem);
    }

    ${responsive.mobile.tablet`
        letter-spacing: 0.05rem;
    `}

    ${responsive.mobile.phone`
        font-size: 2rem;
        letter-spacing: inherit;
        
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
        justify-content: center;
    `}
`

export const NBLinksContainer = styled.div`
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
    margin-left: auto;

    ${responsive.mobile.phone`
        grid-row: 2/-1;
        grid-column: logo-start / links-end;
        display: flex;
        flex-direction: column;
        background-color: ${props => props.theme.components.navbar_bg};
        width: 100%;
        height: calc(100vh - ${NAVBAR_HEIGHT});
        padding: 10rem 5rem;
        transition: background-color 5s;
        z-index: 999;
        transition: all 0.2s ease-in-out;
        transform: scale(0);
        opacity: 0;
        position: fixed;
        top: ${NAVBAR_HEIGHT};

        &.phoneAppear {
            transform: scale(1);
            opacity: 1;
        }
    `}
`

export const NBBurger = styled.div`
    display: block;
    cursor: pointer;
    background-color: ${props => props.theme.components.navbar_text};
    height: 0.1rem;
    width: 4rem;
    transition: all .2s;

    &::before, &::after {
        content: "";
        display: block;
        background-color: ${props => props.theme.components.navbar_text};
        height: inherit;
        width: inherit;
        transition: all .2s;
    }

    &::before {
        transform: translateY(-1rem);
    }

    &::after {
        transform: translateY(1rem);
    }
`
export const NBBurgerContainer = styled.div`
    display: none;
    margin-left: auto;
    margin-right: 2rem;
    grid-row: 1/-1;
    grid-column: links-start / links-end;

    &.clicked ${NBBurger} {
        background-color: transparent;

        &::before {
            transform: rotate(+45deg);
            height: 0.2rem;
        }

        &::after {
            transform: rotate(-45deg);
            height: 0.2rem;
        }
    }

    ${responsive.mobile.phone`
        display: flex;
        height: 100%;
        align-items: center;
    `}
`

export const NavLogo = styled.img`
    width: 100%;
    object-fit: contain;
    cursor: pointer;
`

export const NBLogoFigure = styled.figure`
    height: 100%;
    display: flex;
    align-items: center;
`