import styled from "styled-components";
import { responsive } from "../../../styles/util.styles";

export const CardImgTitleMidContainer = styled.div`
    font-size: 1.6rem;
    width: 30vw;
    margin: 5rem 2rem;
    padding: 2rem;
    background-color: ${props => props.theme.components.card_bg};
    display: grid;
    grid-template-rows: 1fr min-content;
    
    ${responsive.desktop.laptop`
        width: 40vw;
    `};

    ${responsive.mobile.tablet`
        width: 70vw;
    `};

    ${responsive.mobile.phone`
        width: 85vw;
    `};
`;

export const CITMImgContainer = styled.div`
    height: 100%;
`

export const CITMImg = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`

export const CITMTitle = styled.h3`
    text-align: center;
    justify-self: center;
    transform: translateY(-50%);
    width: 80%;
    color: ${props => props.theme.components.card_title_text};
    background-color: ${props => props.theme.components.card_title_bg};
    padding: 2rem;
    border-radius: 1rem;
`

export const CITMFeatures = styled.div`
    font-size: 1.6rem;
`