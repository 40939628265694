import { createGlobalStyle } from "styled-components";

export const GlobalStyleComponent = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding:0;
        box-sizing: inherit;
    }

    html {
        font-size: 62.5%;
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: 'Poppins', serif;
        font-size: 1.6rem;
        color: ${props => props.theme.colors.primary};
    }

    // Typography

    h1 {
        font-size: 6rem;
        font-weight: 600;
        letter-spacing: .1rem;
        color: currentColor;
    }

    h2 {
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: .1rem;
        color: currentColor;
    }

    h3 {
        font-size: 2.5rem;
        font-weight: 600;
        letter-spacing: .1rem;
        color: currentColor;
    }

    p {
        font-size: 2rem;
        letter-spacing: .1rem;
    }
`