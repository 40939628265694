export const ColorTheme = {
    // COLOR THEME //////////////////////////
    primary: "#1e2761",
    primary_1:"#465092",
    primary_2:"#a3aad4",
    secondary: "#e8e8e8",
    secondary_1: "#f7f0f0",
    secondary_2: "#fffaff",
    tertiary: "#7a2048",
    tertiary_1: "#915b73",

    // BASIC COLORS //////////////////////////
    white: "#fff",
    black: "#000",
    success: "#28a745",
    error: "#dc3545",
}

export const ComponentsTheme = {
    // COMPONENTS //////////////////////////
    button_primary_text: ColorTheme.secondary_1,
    button_primary_bg: ColorTheme.primary_1,
    button_primary_text_hover: ColorTheme.secondary,
    button_primary_bg_hover: ColorTheme.primary,

    button_secondary_bg: "unset",
    button_secondary_bg_underline: ColorTheme.primary,
    button_secondary_bg_hover: ColorTheme.tertiary,
    button_secondary_text: ColorTheme.primary,

    card_bg: ColorTheme.secondary_2,
    
    card_logo_bg: ColorTheme.white,

    card_title_bg: ColorTheme.primary,
    card_title_text: ColorTheme.secondary_2,

    navbar_bg: ColorTheme.primary,
    navbar_text: ColorTheme.secondary,

    input_label_text: ColorTheme.primary,
    input_bg: ColorTheme.secondary_2,
    input_focus: ColorTheme.tertiary,

    // Page Section //////////////////////////
    footer_link: 'currentColor',
    footer_link_underline: 'currentColor',

    welcome_bg: ColorTheme.secondary,

    // Pages //////////////////////////
    contact_text: ColorTheme.primary,
    contact_bg: ColorTheme.secondary,
}

export const ImagesTheme = {
    welcome: './imgs/home.jpg',
    contact: './imgs/contact-us.jpg',
}