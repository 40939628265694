import { HomeContainer, HServicesPreview, HWelcomeContainer,HWelcomeHeader, HWelcomeIntro, HServicesPreviewContainer, HContactContainer, HContactMessage} from "./home.styles"
import {SERVICES} from "../../assets/data/services";
import {PrimaryButton, SecondaryButton} from "../../components/buttons/button.component";
import CardLogo from "../../components/cards/card-logo/card-logo.component";
import {PAGE_NAMES} from "../../styles/util.styles"

export default function Home() {
    
    return (
        <HomeContainer>
            <HWelcomeContainer>
                <HWelcomeHeader>
                    {
                        SERVICES.map((service, index) => {
                            return (
                                <HWelcomeIntro key={index}>
                                    {service.title}.
                                </HWelcomeIntro>
                            )
                        })
                    }
                </HWelcomeHeader>
                <SecondaryButton to={PAGE_NAMES.SERVICES}>Learn More &rarr;</SecondaryButton>
            </HWelcomeContainer>

            <HServicesPreviewContainer>
                <HServicesPreview>
                    {
                        SERVICES.map((service, index) => {
                            return (
                                <CardLogo key={index} logo={service.icon} title={service.title} description={service.summary.description} />
                                )
                            })
                        }
                </HServicesPreview>

                <SecondaryButton to={PAGE_NAMES.SERVICES}>View All Services</SecondaryButton>
            </HServicesPreviewContainer>
            <HContactContainer>
                <HContactMessage>
                    We want to help you grow your business. Contact us today.
                </HContactMessage>
                <PrimaryButton to={PAGE_NAMES.CONTACT}>Contact Us</PrimaryButton>
            </HContactContainer>
        </HomeContainer>
    )
}