import { FooterContainer, FLegal, FLinks, FLink } from './footer.styles';

export default function Footer({legal, links}) {
    return (
    <FooterContainer>
        <FLegal>{legal}</FLegal>
        <FLinks>
            {
                links.map((link, index) => {
                    return (
                        <FLink key={index} href={link.address}>{link.text ? link.text : <figure>{link.logo}</figure>}</FLink>
                    )
                })
            }
        </FLinks>
    </FooterContainer>
)}