import { ContactUsBackground, CUContainer, CUPhone, CUInformation, CUHeader, CUForm, CUSubmitButtonContainer, CUErrorMessage, CUSuccessMessage} from "./contact-us.styles";
import { PrimaryButton } from "../../components/buttons/button.component";
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import FormGroupFlyingLabel, {FORM_GROUP_TYPES} from "../../components/forms/formgroup-flyinglabel/formgroup-flyinglabel.component";

export default function ContactUs() {
    
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onChangeCallback = (e) => {
        showError && setShowError(false);
        showSuccess && setShowSuccess(false);
        setIsSubmitted(false);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        
        const templateParams = {
            name: e.target.name.value,
            phone: e.target.phone.value,
            email: e.target.email.value,
            message: e.target.message.value
        };

        setIsLoading(true);
        emailjs
        .send(process.env.REACT_APP_EMAILJS_SERVICE_KEY, process.env.REACT_APP_EMAILJS_TEMPLATE_KEY, templateParams, {
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        })
        .then(
            () => {
                setShowSuccess(true);
                setIsSubmitted(true);
            }
        )
        .catch((error) => {
            setShowError(true);
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <ContactUsBackground>
            <CUContainer>
                <CUHeader>
                    Contact Us
                </CUHeader>
                <CUForm onSubmit={sendEmail}>

                    <FormGroupFlyingLabel
                        placeholder="Full Name"
                        type={FORM_GROUP_TYPES.TEXT}
                        onChangeCallback={onChangeCallback}
                        name="name" 
                        isSubmitted={isSubmitted} />

                    <FormGroupFlyingLabel
                        placeholder="Phone Number"
                        type={FORM_GROUP_TYPES.PHONE}
                        onChangeCallback={onChangeCallback}
                        name="phone" 
                        isSubmitted={isSubmitted}
                        format="(xxx) xxx-xxxx" />

                    <FormGroupFlyingLabel
                        placeholder="Email Address"
                        type={FORM_GROUP_TYPES.EMAIL}
                        onChangeCallback={onChangeCallback}
                        name="email"
                        required 
                        isSubmitted={isSubmitted} />

                    <FormGroupFlyingLabel
                        placeholder="Your Message..."
                        type={FORM_GROUP_TYPES.TEXTAREA}
                        onChangeCallback={onChangeCallback}
                        name="message"
                        rows="6"
                        isSubmitted={isSubmitted} />

                    <CUSubmitButtonContainer>
                        <PrimaryButton loading={isLoading} disabled={isLoading}>Send</PrimaryButton>
                    </CUSubmitButtonContainer>

                    <CUSuccessMessage style={{display: showSuccess ? 'block' : 'none'}}>Your message was sent!</CUSuccessMessage>
                    <CUErrorMessage style={{display: showError ? 'block' : 'none'}}>We encoutered an error sending your message. Please try again. If it happens again please give us a call.</CUErrorMessage>
                </CUForm>
                
                <CUInformation>
                    <CUPhone>Phone: (571) 314-0194</CUPhone>
                </CUInformation>
            </CUContainer>
        </ContactUsBackground>
    );
}
