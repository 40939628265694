import styled from "styled-components";

export const SpinnerContainer = styled.figure`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`