import styled from "styled-components";

export const CardLogoContainer = styled.div`
    display: grid;
    grid-template-columns: min-content [text-begin] 1fr [text-end];
    grid-template-rows: min-content 1fr;
    background-color: ${props => props.theme.components.card_logo_bg};
    height: '100%';
    width: '100%';
    column-gap: 2rem;
    padding: 2rem;

`

export const CLTitle = styled.h3`
    display: flex;
    align-items: center;
`

export const CLLogoContainer = styled.figure`
    height: '5rem';
    width: '5rem';
`

export const CLDescriptions = styled.p`
    color: ${props => props.theme.colors.tertiary};
    grid-column: text-begin / text-end;
`