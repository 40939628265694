import { FormGroup } from "./formgroup-flyinglabel.styles"
import FormLabel from "../form-label/form-label.component"
import FormInput from "../form-input/form-input.component"
import { useId } from "react";

export const FORM_GROUP_TYPES = {
    TEXT: 'text',
    PASSWORD: 'password',
    EMAIL: 'email',
    PHONE: 'tel',
    TEXTAREA: 'textarea',
}

export default function FormGroupFlyingLabel ({onChangeCallback, isSubmitted, labelText, ...otherProps}) {
    const placeholder = otherProps.placeholder
    const id = useId()

    const labelParams = {htmlFor: id, labelText: labelText ?? placeholder} 
    let userInputParams = { ...otherProps, id, onChangeCallback, isSubmitted }

    return (
        <FormGroup>
            <FormInput {...userInputParams} />
            <FormLabel {...labelParams} />
        </FormGroup>
    )
}