import styled from 'styled-components';
import { responsive, tintedBackgroundImage } from "../../styles/util.styles";

export const ContactUsBackground = styled.div`
    min-height: 95vh;
    display: grid;
    grid-template-rows: min-content 1fr;
    justify-items: center;
    align-items: center;
    color: ${props => props.theme.components.contact_text};
    background-color: ${props => props.theme.components.contact_bg};
    ${props => tintedBackgroundImage(props.theme.colors.secondary_2, props.theme.images.contact, 80)}`

export const CUContainer = styled.div`
    width: 50%;

    ${responsive.mobile.phone`
        width: 85%;
    `};
`

export const CUForm = styled.form`
    margin-bottom: 6rem;
`

export const CUSubmitButtonContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
`

export const CUHeader = styled.h1`
    text-align: center;
    padding: 6rem 0;
`

export const CUSuccessMessage = styled.span`
    font-size: 1.5rem;
    color: ${props => props.theme.colors.success};

`

export const CUErrorMessage = styled(CUSuccessMessage)`
    color: ${props => props.theme.colors.error};
`

export const CUInformation = styled.div`
    font-size: 1.6rem;
    display: flex;
    width: 100%;
    margin-bottom: 5rem;

    ${responsive.mobile.tablet`
        flex-direction: column;
    `};
`

export const CUPhone = styled.p`
    margin-right: auto;
    font-size: inherit;
`

export const CUEmail = styled.p`
    font-size: inherit;
`

