import {css} from 'styled-components';
import { FormLabelStyle } from '../components/forms/form-label/form-label.styles';

// MISC STYLES ////////////////////////////////////////////
export const LinkStyleReset = css`
    text-decoration: none;
    color: currentColor;
`

export const InputStyle = css`
    font-family: inherit;
    border: none;
    background-color: ${props => props.theme.components.input_bg};
    padding:  1rem;
    font-size: 1.6rem;
    width: 100%;
    margin-bottom: 1rem;
    border-bottom: .3rem solid ${props => props.theme.components.input_bg};
    transition: all .3s ease-in-out;
    opacity: .75;
 
    &:focus {
        outline: none;
        border-bottom: .3rem solid ${props => props.theme.components.input_focus};
    }

    &:placeholder-shown + ${FormLabelStyle} {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }
`

// MEDIA QUERIES ////////////////////////////////////////////
const media = {
    sm: `${600/16}em`,
    md: `${750/16}em`,
    lg: `${992/16}em`,
    xl: `${1200/16}em`
}

export const responsive = {
    mobile: {
        phone: (...args) => css`
            @media only screen and (max-width: ${media.sm}) {
                ${css(...args)}
            }
        `,
        tablet : (...args) => css`
            @media only screen and (max-width: ${media.md}) {
                ${css(...args)}
            }
        `
    },
    desktop: {
        laptop: (...args) => css`
            @media only screen and (max-width: ${media.lg}) {
                ${css(...args)}
            }
        `,
        desktop: (...args) => css`
            @media only screen and (max-width: ${media.xl}) {
                ${css(...args)}
            }
        `
    }
}


// MISC FUNCTIONS ////////////////////////////////////////////
export function hexColorOpacity(hex, opacity) {
    return hex + hex_a[opacity];
}

export function tintedBackgroundImage(color, img, opacity, position = 'center') {
    return css`
        background-image: linear-gradient(${hexColorOpacity(color, opacity)}, ${hexColorOpacity(color, opacity)}), url(${img});
        background-size: cover;
        background-position: ${position};
    `
}

const hex_a = {
    100: 'FF',
    99: 'FC', 98: 'FA', 97: 'F7', 96: 'F5', 95: 'F2', 94: 'F0', 93: 'ED', 92: 'EB', 91: 'E8', 90: 'E6',
    89: 'E3', 88: 'E0', 87: 'DE', 86: 'DB', 85: 'D9', 84: 'D6', 83: 'D4', 82: 'D1', 81: 'CF', 80: 'CC',
    79: 'C9', 78: 'C7', 77: 'C4', 76: 'C2', 75: 'BF', 74: 'BD', 73: 'BA', 72: 'B8', 71: 'B5', 70: 'B3', 
    69: 'B0', 68: 'AD', 67: 'AB', 66: 'A8', 65: 'A6', 64: 'A3', 63: 'A1', 62: '9E', 61: '9C', 60: '99', 
    59: '96', 58: '94', 57: '91', 56: '8F', 55: '8C', 54: '8A', 53: '87', 52: '85', 51: '82', 50: '80', 
    49: '7D', 48: '7A', 47: '78', 46: '75', 45: '73', 44: '70', 43: '6E', 42: '6B', 41: '69', 40: '66', 
    39: '63', 38: '61', 37: '5E', 36: '5C', 35: '59', 34: '57', 33: '54', 32: '52', 31: '4F', 30: '4D', 
    29: '4A', 28: '47', 27: '45', 26: '42', 25: '40', 24: '3D', 23: '3B', 22: '38', 21: '36', 20: '33', 
    19: '30', 18: '2E', 17: '2B', 16: '29', 15: '26', 14: '24', 13: '21', 12: '1F', 11: '1C', 10: '1A',
    9: '17', 8: '14', 7: '12', 6: '0F', 5: '0D', 4: '0A', 3: '08', 2: '05', 1: '03', 0: '00' }


// BOX SHADOWS ////////////////////////////////////////////
export const BOX_SHADOW_OFFSET = {
    horizontal: 'horizontal',
    vertical: 'vertical',
    both: 'both',
    no_offset: 'no_offset',
}

export const BOX_SHADOW_SIZE = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
}

export const box_shadow = (size, offsetDirection) => {
    let offset = 0;
    let blur = 0;

    switch (size) {
        case BOX_SHADOW_SIZE.xs:
            offset = 0.5;
            blur = 1;
            break;
        case BOX_SHADOW_SIZE.sm:
            offset = 0.75;
            blur = 1.25;
            break;
        case BOX_SHADOW_SIZE.md:
            offset = 1;
            blur = 1.5;
            break;
        case BOX_SHADOW_SIZE.lg:
            offset = 1.5;
            blur = 2;
            break;
        default:
            break;
    }

    let vertical = 0;
    let horizontal = 0;

    switch (offsetDirection) {
        case BOX_SHADOW_OFFSET.horizontal:
            horizontal = offset;
            break;
        case BOX_SHADOW_OFFSET.vertical:
            vertical = offset;
            break;
        case BOX_SHADOW_OFFSET.both:
            vertical = offset;
            horizontal = offset;
            break;
        default:
            break;
    }

    return css`
        box-shadow: ${horizontal}rem ${vertical}rem ${blur}rem rgba(0, 0, 0, 0.2);
    `
}


// PAGE NAMES ////////////////////////////////////////////
export const PAGE_NAMES = {
    HOME: '/home',
    SERVICES: '/services',
    CONTACT: '/contact',
    PORTFOLIO: '/portfolio',
}