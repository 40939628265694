import styled from "styled-components"

export const BlogSectionContainer = styled.div``

export const BSTitle = styled.h2``

export const BSParagraphSection = styled.div`
    margin-bottom: 2rem;
`

export const BSParagraphText = styled.p``

export const BSParagraphImg = styled.div``