import { BlogImgContainer, BIStyle, BICaption, BIContainer } from "./blog-img.styles";

export default function BlogImg({img}) {

    if(!img || !img.src || !img.alt || !img.figcaption) return (<></>);

    return (
        <BlogImgContainer>
            <BIContainer>
                <BIStyle src={img.src} alt={img.alt} />
                <BICaption>{img.figcaption}</BICaption>
            </BIContainer>
        </BlogImgContainer>
    )
}