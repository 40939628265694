import { NAVBAR_HEIGHT } from "../../components/navbars/navbar.styles"
import styled from 'styled-components';
import { responsive, tintedBackgroundImage } from '../../styles/util.styles';

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const HWelcomeContainer = styled.div`
    height: calc(100vh - ${NAVBAR_HEIGHT});
    ${props => tintedBackgroundImage(props.theme.colors.secondary_2, props.theme.images.welcome, 80, 'right')}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
`

export const HWelcomeIntro = styled.div`
`

export const HWelcomeHeader = styled.h1`
    ${responsive.mobile.phone`
        font-size: 5rem;
    `}

    margin-bottom: 5rem;
`

export const HServicesPreview = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 5rem 2rem;

    ${responsive.mobile.tablet`
        grid-template-columns: 1fr;
    `}
`

export const HServicesPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
`

export const HContactContainer = styled.div`
    ${props => tintedBackgroundImage(props.theme.colors.secondary_2, props.theme.images.contact, 80, 'left')}
    width: 100%;
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10rem 0;
`

export const HContactMessage = styled.h3`
    margin: 5rem 2rem;
    margin-bottom: 5rem;
    text-align: center;
`